import { useState } from 'react';
import axios from 'axios';
import Spinner from '@ux/spinner';
import { useIntl } from 'react-intl';
import Button from '@ux/button';
import Growl from '@ux/growl';

import util from '../../util';
import { useContext } from '../RenderContext';

const AddToCart = ({
  items,
  canInteract,
  cta
}: {
  items: {
    id: string;
    quantity: number;
  }[],
  canInteract: boolean;
  cta: string;
}) => {
  const {
    settings
  } = useContext();
  const {
    envPrefix,
    privateLabelId,
    itc
  } = settings;
  const {
    formatMessage
  } = useIntl();
  const [isAdding, setIsAdding] = useState(false);
  const action = `https://www.${envPrefix}secureserver.net/api/v1/cart/${privateLabelId}?itc=${itc}&redirect=false`;
  const addItems = () => {
    setIsAdding(true);
    axios.post(action, { items }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const nextStep = response.data.nextStepUrl;
      if (new URL(nextStep).hostname.startsWith('cart') === false) {
        window.location.href = nextStep;
      }
      else {
        document.dispatchEvent(new CustomEvent('px.headerCart.refresh'));
        document.dispatchEvent(new CustomEvent('px.headerCart.open'));
        setIsAdding(false);
      }
    }).catch(() => {
      Growl.addGrowlMessage({
        id: 'add-to-cart-error-growl',
        title: formatMessage({ id: 'products.growls.errors.addToCart.title' }),
        children: formatMessage({ id: 'products.growls.errors.addToCart.content' }),
        emphasis: 'critical',
        lifetime: 5000
      });
      setIsAdding(false);
    });
  };
  return (
    <div className="product-add-wrapper">
      <Button
        design="primary"
        className="product-addToCart"
        type="submit"
        disabled={ !canInteract || isAdding }
        onClick={ () => {
          addItems();
        } }
        icon={ isAdding ? <Spinner size="sm" /> : null }
        data-eid={ `storefront.product.${util.snakeCase(items[0].id)}.add_to_cart.button.click` }
        text={ formatMessage({ id: `products.${cta}` }) }
      />
    </div>
  );
};

AddToCart.displayName = 'AddToCart';

export default AddToCart;
