import { useIntl } from 'react-intl';
import Button from '@ux/button';
import util from '../../util';
import { useContext } from '../RenderContext';

const LegacyAddToCart = ({
  items,
  canInteract,
  cta
}: {
  items: {
    id: string;
    quantity: number;
  }[],
  canInteract: boolean;
  cta: string;
}) => {
  const {
    settings
  } = useContext();
  const {
    envPrefix,
    privateLabelId,
    itc
  } = settings;
  const {
    formatMessage
  } = useIntl();
  const action = `https://www.${envPrefix}secureserver.net/api/v1/cart/${privateLabelId}?itc=${itc}&redirect=true`;
  return (
    <form method="POST" action={ action }>
      <input type="hidden" name="items" value={ JSON.stringify(items) } />
      <Button
        design="primary"
        className="product-addToCart"
        type="submit"
        disabled={ !canInteract }
        data-eid={ `storefront.product.${util.snakeCase(items[0].id)}.add_to_cart.button.click` }
        text={ formatMessage({ id: `products.${cta}` }) }
      />
    </form>
  );
};

LegacyAddToCart.displayName = 'AddToCart';

export default LegacyAddToCart;
