import { useEffect, useState } from 'react';
import { FeatureFlags, useFeatureFlag } from '../../hooks/switchboard';
import { Product } from '../../types';
import LegacyAddToCart from './LegacyAddToCart';
import AddToCart from './AddToCart';

const AddToCartButton = (
  {
    product,
    cta
  }: {
    product: Product
    cta: string
  }
) => {
  const items = [{
    id: product.id,
    quantity: 1
  }];
  const ajaxFlag = useFeatureFlag(FeatureFlags.AJAX_ADD_TO_CART);
  const headerFlag = useFeatureFlag(FeatureFlags.HEADER_CART);
  const [canInteract, setCanInteract] = useState(false);
  useEffect(() => {
    setCanInteract(true);
  }, []);
  if (headerFlag && ajaxFlag) {
    return (
      <AddToCart
        items={ items }
        canInteract={ canInteract }
        cta={ cta }
      />
    );
  }
  return (
    <LegacyAddToCart
      items={ items }
      canInteract={ canInteract }
      cta={ cta }
    />
  );
};

AddToCartButton.displayName = 'AddToCartButton';

export default AddToCartButton;
