import { useContext } from '../../components/RenderContext';

const useSwitchboard = () => {
  const context = useContext();
  const switchboard = context?.settings?.switchboard;

  return switchboard;
};

export default useSwitchboard;
