import { enums } from 'rcc-helpers';

import { useContext } from '../RenderContext';
import withPageTitle from '../../hoc/withPageTitle';
import Turnkey from './turnkey';
import Super from './super';

export const Home = () => {
  const {
    settings
  } = useContext();
  return settings.resellerTypeId === enums.resellerType.SUPER
    ? <Super />
    : <Turnkey />;
};

export default withPageTitle(Home);
