import React, { JSX, Fragment } from 'react';
import { IntlProvider, IntlShape, WithIntlProps } from 'react-intl';
import { Route } from 'react-router-dom';
import { init as initApm } from '@elastic/apm-rum';
import { ApmRoutes } from '@elastic/apm-rum-react';
import Growl from '@ux/growl';

import routes from './routes';
import { Provider } from '../src/components/RenderContext';
import Disclaimer from './components/Disclaimer';
import './vendors-styles';
import { ContextProps } from './types';
import { FeatureFlags } from './hooks/switchboard';
import { enableCart } from './util';

type Prop = ((props: {}) => JSX.Element) | (React.FC<WithIntlProps<{
  intl: IntlShape;
}>> & {
  WrappedComponent: React.ComponentType<{
      intl: IntlShape;
  }>;
});

const Storefront = ({ renderContext }: {
  renderContext: ContextProps,
}) => {
  if (renderContext.elastic) {
    initApm({
      serviceName: 'sf',
      environment: renderContext.elastic.ELASTIC_APM_ENVIRONMENT,
      serverUrl: renderContext.elastic.ELASTIC_APM_SERVER_URL,
      serviceVersion: renderContext.elastic.ELASTIC_APM_SERVICE_VERSION || 'local'
    });
  }
  return (
    <IntlProvider locale={ renderContext.settings.locale } messages={ renderContext.settings.messages }>
      <Fragment>
        <Provider value={ renderContext }>
          <ApmRoutes>
            {routes.filter(r => !!r.component).map((props: {
              key: string,
              path: string,
              component?: Prop,
            }) => {
              const {
                key,
                path,
                component: Page
              } = props;
              if (renderContext.settings.switchboard[FeatureFlags.HEADER_CART]) {
                enableCart();
              }
              return (
                <Route
                  key={ key }
                  path={ path }
                  element={ Page ? <Page /> : null }
                />
              );
            })}
          </ApmRoutes>
        </Provider>
        <Disclaimer
          disclaimer={ renderContext.tag?.disclaimer }
          tagId={ renderContext.tag?.id }
          displayTaxesAndFees={ renderContext.settings.displayTaxesAndFees }
        />
        <Growl />
      </Fragment>
    </IntlProvider>
  );
};

export default Storefront;
