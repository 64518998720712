import { RefObject, useMemo, useRef, useState } from 'react';
import {
  FormattedMessage,
  useIntl
} from 'react-intl';
import Button from '@ux/button';
import Modal from '@ux/modal';
import { isMobile } from '@ux/component-utilities';

import Card from '../Card';
import { Product } from '../../types';
import AddToCart from './AddToCartWrapper';

const ProductCard = ({
  breakpoint,
  product,
  cta = 'addToCart'
}: {
  breakpoint?: string,
  product: Product,
  cta?: string,
}) => {
  const { formatMessage } = useIntl();

  const ref = useRef({}) as RefObject<HTMLDivElement>;

  const [shouldShowDisclaimersModal, setShouldShowDisclaimersModal] = useState(false);

  const showDisclaimersButton = useMemo(() => !isMobile(breakpoint), [breakpoint]);


  const hasDisclaimers = product.disclaimers != null && product.disclaimers !== '';
  const shouldShowDisclaimersButton = hasDisclaimers && showDisclaimersButton;
  const shouldShowDisclaimersText = hasDisclaimers && !shouldShowDisclaimersButton;

  const details = (
    <div
      className="product-description"
      dangerouslySetInnerHTML={{ __html: product.content }}
    />
  );

  const disclaimers = (
    <div dangerouslySetInnerHTML={{ __html: product.disclaimers ?? '' }} />
  );

  const price = (
    <p className="product-price">
      { product.salePrice && (
        <span>
          <s className="product-amount text-muted">
            { product.listPrice }
          </s>&nbsp;
        </span>
      ) }

      <strong className="product-amount text-primary">
        { product.salePrice || product.listPrice }
        { /* keep these together to preserve the breaking space */ }
      </strong> <FormattedMessage id={ `products.terms.${product.oneTimeCharge ? 'each' : product.term}` }>
        { text => <span className="product-term">{text}</span> }
      </FormattedMessage>
    </p>
  );

  const disclaimersButton = (
    <div className="product-disclaimers-btn">
      <Button
        size="small"
        design="inline"
        text={ formatMessage({ id: 'products.details.disclaimers' }) }
        onClick={ () => setShouldShowDisclaimersModal(true) }
      />
    </div>
  );

  const productDisclaimers = (
    <div className="product-disclaimers-text">
      { disclaimers }
    </div>
  );

  const productBody = (
    <div>
      <h4 className="product-title">{ product.alias || product.title }</h4>
      { price }
      <AddToCart
        product={ product }
        cta={ cta }
      />
      { details }
      { shouldShowDisclaimersText && productDisclaimers }
    </div>
  );

  const disclaimersModal = (
    <Modal
      id={ product.id }
      onClose={ () => setShouldShowDisclaimersModal(false) }
    >
      <div className="disclaimer-modal-content">
        <h4 className="product-title">
          <FormattedMessage id="products.details.disclaimers" />
        </h4>
        { productDisclaimers }
      </div>
    </Modal>
  );

  const footerDisclaimerButton = shouldShowDisclaimersButton ? disclaimersButton : null;

  return (
    <Card
      className="product-card"
      footer={ footerDisclaimerButton }
    >
      <div className="product-details" ref={ ref }>
        { productBody }
      </div>
      { shouldShowDisclaimersModal && disclaimersModal }
    </Card>
  );
};

export default ProductCard;
