import { cookie, request } from '@ux/util';
import camelCase from 'camelcase';
import { Navigation, Settings } from './types';

type CategoryType = {
  isHidden?: boolean,
  children: {
    [key: string]: {
      isHidden?: boolean,
      url?: string,
    }
  }
}

type ParsedTokenType = {
  firstName: string,
  lastName: string,
  shopperId: string
}

type UnparsedTokenType = {
  name: string,
  lastname: string,
  shopperid: string
}

const shouldShowChild = (category: CategoryType | undefined, key: string) => category &&
  !category.isHidden &&
  category.children &&
  key in category.children &&
  !category.children[key].isHidden &&
  !category.children[key].url;

export default {
  snakeCase(value: string) {
    return value.replace(/-/g, '_');
  },

  stripNonNumerals(value: string) {
    return value.replace ? value.replace(/[^0-9]/g, '') : value;
  },

  buildDomainTransferUrl({
    envPrefix,
    domain,
    privateLabelId,
    progId,
    itc
  }: Partial<Settings>) {
    // eslint-disable-next-line max-len
    return `https://dcc.${envPrefix}secureserver.net/domains/transfer-in/${encodeURIComponent(domain || '')}?plid=${privateLabelId}&prog_id=${progId}&itc=${itc}` as unknown as Location;
  },

  getShopper(
    {
      envPrefix,
      privateLabelId
    } : Settings,
    callback: (error?: Error | null, token?: ParsedTokenType) => void
  ) {
    const infoIdp = cookie.get('info_idp');

    if (!infoIdp) {
      return callback(new Error('No info_idp cookie present'));
    }

    const sid = JSON.parse(infoIdp).info_shopperId;
    const guiUrl = `https://gui.${envPrefix}secureserver.net/pcjson/salesheader?plId=${privateLabelId}&sid=${sid}`;

    request.get(guiUrl, { jsonp: true }, (error: Error, token: UnparsedTokenType) => {
      if (error) {
        return callback(error);
      }
      else if (!token || !token.shopperid) {
        return callback();
      }

      const {
        name: firstName,
        lastname: lastName,
        shopperid: shopperId
      } = token;

      return callback(null, {
        firstName,
        lastName,
        shopperId
      });
    });
  },

  shouldRenderEnglishOnlySupportDisclaimer(settings: Settings) {
    return !settings.providesOwnSupport && !(settings.locale ?? '').startsWith('en-');
  },

  getProductCalloutKeys(navigation?: Navigation) {
    if (!navigation || Object.entries(navigation).length === 0) {
      return [];
    }

    return [
      shouldShowChild(navigation.hosting, 'cpanel') && 'cpanel',
      shouldShowChild(navigation.workspace, 'professionalEmail') && 'professionalEmail',
      shouldShowChild(navigation.domains, 'domainRegistration') && 'domainRegistration',
      shouldShowChild(navigation.workspace, 'microsoft365') && 'microsoft365'
    ].filter(Boolean);
  },

  redirect: (target: string) => {
    // `global.location.assign` is problematic in tests. Either isn’t defined
    // or is read-only and can’t be stubbed/spied.
    global?.location?.assign && global.location.assign(target);
  },

  shouldRenderConfig: ({ plan, packageId } :{
    plan: string,
    packageId: string
  }) => !!(plan || packageId),

  getPageNameLocaleKey: (path = '', delimiter = '/') => camelCase(path.split(delimiter).pop() as string)
};

// there is a try because backend will throw an error if document is not defined
// frontend will still see it and not throw any errors.
export const enableCart = () => {
  try {
    if (!document) {
      return null;
    }
    // Enable the cart event dispatch in case the header cart is not ready
    document.addEventListener('px.headerCart.ready', () => {
      document.dispatchEvent(new CustomEvent('px.headerCart.enable', { detail: { appKey: 'reseller-storefront' } }));
    });
    // dispatch the cart event in case the header cart is ready
    document.dispatchEvent(new CustomEvent('px.headerCart.enable', { detail: { appKey: 'reseller-storefront' } }));
  }
  // silently fail
  // eslint-disable-next-line no-empty
  catch (error) {}
};
